import {FieldConfig} from "../../dynamic-form/models/field-config.interface";
import {Validators} from "@angular/forms";

export class QuestDefinitions
{
    public static done: FieldConfig[] = [
        {
            type: 'div',
            label: 'Q.THANKS',
            style: 'mat-title'
        },
        {
            type: 'spacer'
        },
        {
            type: 'div',
            label: 'Q.SUBMITED'
        }
    ];

    public static quest360T: FieldConfig[] = [
        {
            type: 'div',
            label: 'Q.GREETING',
            style: 'mat-title'
        },
        {
            type: 'div',
            value: 'Q.TEXT'
        },
        {
            type: 'div',
            label: 'Q.V1',
            style: 'v1'
        },
        {
            type: 'div',
            label: 'Q.V2',
            style: 'v2'
        },
        {
            type: 'div',
            label: 'Q.V3',
            style: 'v3'
        },
        {
            type: 'div',
            label: 'Q.V4',
            style: 'v4'
        },
        {
            type: 'div',
            label: 'Q.V5',
            style: 'v5'
        },
        {
            type: 'div',
            label: 'Q.VNA',
            style: 'vna'
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q1',
            validation: [Validators.required]
        },
        {
            type: 'textarea2',
            label: '',
            name: 'Q47',
            rows: 3,
            validation: [Validators.required, Validators.minLength(1)]
        },
        {
            label: 'SUBMIT',
            name: 'submit',
            type: 'button',
            disabled: false
        }
    ];

    public static quest360_reverse: FieldConfig[] = [
        {
            type: 'div',
            label: 'Q.GREETING',
            style: 'mat-title'
        },
        {
            type: 'div',
            value: 'Q.TEXT'
        },
        {
            type: 'div',
            label: 'Q.V1R',
            style: 'v5'
        },
        {
            type: 'div',
            label: 'Q.V2R',
            style: 'v4'
        },
        {
            type: 'div',
            label: 'Q.V3R',
            style: 'v3'
        },
        {
            type: 'div',
            label: 'Q.V4R',
            style: 'v2'
        },
        {
            type: 'div',
            label: 'Q.V5R',
            style: 'v1'
        },
        {
            type: 'div',
            label: 'Q.VNA',
            style: 'vna'
        },
        {
            type: 'spacer'
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q1',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q2',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q3',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q4',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q5',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q6',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q7',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q8',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q9',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q10',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q11',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q12',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q13',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q14',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q15',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q16',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q17',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q18',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q19',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q20',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q21',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q22',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q23',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q24',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q25',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q26',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q27',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q28',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q29',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q30',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q31',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q32',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q33',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q34',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q35',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q36',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q37',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q38',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q39',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q40',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q41',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q42',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q43',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q44',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q45',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q46',
            validation: [Validators.required]
        },
        {
            type: 'textarea2',
            label: '',
            name: 'Q47',
            rows: 3,
            validation: [Validators.required, Validators.minLength(1)]
        },
        {
            type: 'textarea2',
            label: '',
            name: 'Q48',
            rows: 3,
            validation: [Validators.required, Validators.minLength(1)]
        },
        {
            type: 'textarea2',
            label: '',
            name: 'Q49',
            rows: 3,
            validation: [Validators.required, Validators.minLength(1)]
        },
        {
            label: 'SUBMIT',
            name: 'submit',
            type: 'button',
            disabled: false
        }
    ];

    public static quest360: FieldConfig[] = [
        {
            type: 'div',
            label: 'Q.GREETING',
            style: 'mat-title'
        },
        {
            type: 'div',
            value: 'Q.TEXT'
        },
        {
            type: 'div',
            label: 'Q.V1',
            style: 'v1'
        },
        {
            type: 'div',
            label: 'Q.V2',
            style: 'v2'
        },
        {
            type: 'div',
            label: 'Q.V3',
            style: 'v3'
        },
        {
            type: 'div',
            label: 'Q.V4',
            style: 'v4'
        },
        {
            type: 'div',
            label: 'Q.V5',
            style: 'v5'
        },
        {
            type: 'div',
            label: 'Q.VNA',
            style: 'vna'
        },
        {
            type: 'spacer'
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q1',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q2',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q3',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q4',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q5',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q6',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q7',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q8',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q9',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q10',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q11',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q12',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q13',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q14',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q15',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q16',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q17',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q18',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q19',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q20',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q21',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q22',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q23',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q24',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q25',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q26',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q27',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q28',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q29',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q30',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q31',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q32',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q33',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q34',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q35',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q36',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q37',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q38',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q39',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q40',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q41',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q42',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q43',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q44',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q45',
            validation: [Validators.required]
        },
        {
            type: 'radio2',
            label: '',
            name: 'Q46',
            validation: [Validators.required]
        },
        {
            type: 'textarea2',
            label: '',
            name: 'Q47',
            rows: 3,
            validation: [Validators.required, Validators.minLength(1)]
        },
        {
            type: 'textarea2',
            label: '',
            name: 'Q48',
            rows: 3,
            validation: [Validators.required, Validators.minLength(1)]
        },
        {
            type: 'textarea2',
            label: '',
            name: 'Q49',
            rows: 3,
            validation: [Validators.required, Validators.minLength(1)]
        },
        {
            label: 'SUBMIT',
            name: 'submit',
            type: 'button',
            disabled: false
        }
    ];

}
