<ngx-spinner></ngx-spinner>

<!-- CONTENT -->
<div class="content p-24" >
    <div #contentRef>
        <dynamic-form
            [config]="formConfig"
            [fields]="fields"
            #form="dynamicForm"
            (submit)="submit($event)">
        </dynamic-form>
       <!-- {{ form.valid }}
        {{ form.value | json }}-->
    </div>
</div>
<!-- / CONTENT -->

<!--
-->
