import {Component, AfterViewInit, ViewChild, OnInit, OnDestroy, ElementRef} from '@angular/core';
import {locale as english} from './i18n/en';
import {locale as slovak} from './i18n/sk';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Order} from "../../_models/order";
import {AbstractControl, Validators} from "@angular/forms";
import {DynamicFormComponent} from "../../dynamic-form/containers/dynamic-form/dynamic-form.component";
import {FormConfig} from "../../dynamic-form/models/form-config.interface";
import {FieldConfig} from "../../dynamic-form/models/field-config.interface";
import {QuestDefinitions} from "./questDefs";
import {NgxSpinnerService} from "ngx-spinner";
import {QuestionaireService} from "./questionaire.service";
import {AuthenticationService} from "../../_services/authentication.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-questionaire',
    templateUrl: './questionaire.component.html',
    styleUrls: ['./questionaire.component.scss']
})
export class QuestionaireComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild("contentRef") contentRef: ElementRef;
    @ViewChild(DynamicFormComponent, {static: false}) form: DynamicFormComponent;

    formConfig: FormConfig = {};

    formConfig_360:FormConfig = {
        options: [
            {name: "S.V1", color: "#FF0000", value: "1", style:"1"},
            {name: "S.V2", color: "#FFC000", value: "2", style:"2"},
            {name: "S.V3", color: "#92D14F", value: "3", style:"3"},
            {name: "S.V4", color: "#01B0F1", value: "4", style:"4"},
            {name: "S.V5", color: "#01B0F1", value: "5", style:"5"},
            {name: "S.V0", color: "", value: "0", style:"0"},
        ]
    };

    formConfig_360Reverse:FormConfig = {
       options: [
            {name: "S.V5", color: "#01B0F1", value: "1", style:"5"},
            {name: "S.V4", color: "#01B0F1", value: "2", style:"4"},
            {name: "S.V3", color: "#92D14F", value: "3", style:"3"},
            {name: "S.V2", color: "#FFC000", value: "4", style:"2"},
            {name: "S.V1", color: "#FF0000", value: "5", style:"1"},
            {name: "S.V0", color: "", value: "0", style:"0"}
        ]
    };

    fields: FieldConfig[] = [];

    currentOrderId:string = "";

    constructor(private translationLoader: FuseTranslationLoaderService,
                private route: ActivatedRoute,
                private spinner: NgxSpinnerService,
                private questionaireService: QuestionaireService,
                private authenticationService: AuthenticationService,
                private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService) {
        this.translationLoader.loadTranslations(english, slovak);
    }

    ngOnInit() {

        this.route.params.subscribe(params => {
            //console.log(params);
            this.selectQuestionaire(params.id);
        });

    }

    selectQuestionaire(id:string):void
    {
        this.spinner.hide();

        this.currentOrderId = id;

        let isDoneMode:boolean = this.route.snapshot.routeConfig.path == "qd/:id";

        let order:Order = this.questionaireService.getOrderByKey(id);

        if(order.isSubmited && !isDoneMode)
        {
            this.gotoDone();
            return;
        }

        if(isDoneMode)
        {
            this.fields = QuestDefinitions.done;

            //this.contentRef.nativeElement.scrollTo( 0, 0 );
            //window.scrollTo(0,0);
        }
        else
        {
            switch (order.quizType)
            {
                case "test":
                    this.formConfig = this.formConfig_360;
                    this.fields = QuestDefinitions.quest360T;
                    break;
                case "360R":
                    this.formConfig = this.formConfig_360Reverse;
                    this.fields = QuestDefinitions.quest360_reverse;
                    break;
                case "360":
                default:
                    this.formConfig = this.formConfig_360;
                    this.fields = QuestDefinitions.quest360;
                    break;
            }
        }

        this.questionaireService.changeOrder(order);
    }

    ngAfterViewInit() {
        let previousValid = this.form.valid;
        this.form.changes.subscribe(() => {
            if (this.form.valid !== previousValid) {
                previousValid = this.form.valid;
                //this.form.setDisabled('submit', !previousValid);
            }
        });

        //this.form.setDisabled('submit', true);

    }

    ngOnDestroy()
    {
        this.questionaireService.changeOrder(null);
    }

    submit(value:{[name: string]: any}) {

        if(!this.form.valid)
        {
            let str:string = "";
            for(let controlName in this.form.form.controls)
            {
                let control:AbstractControl = this.form.form.controls[controlName];
                if(control && control.invalid)
                {
                    str += controlName + ".\n";
                }
            }

            this.toastr.info(
                str,
                this.translate.instant("Q.NOT_FILLED_TITTLE"));
        }
        else
        {
            this.authenticationService.submitOrder(this.currentOrderId, value)
                .subscribe(
                    data => {
                        this.router.navigate(["/intro"]);
                        this.toastr.success(
                            this.translate.instant("Q.SUBMITED"),
                            this.translate.instant("Q.THANKS")
                        );
                        //this.selectQuestionaire(this.currentOrderId);
                    },
                    error => {
                        //alert("Cannot save the form. Please try again.");
                        this.toastr.error(
                            this.translate.instant("Q.NOT_SENT"),
                            this.translate.instant("Q.NOT_SENT_TITTLE"));
                    });
        }

    }

    gotoDone():void
    {
        this.router.navigate(['/qd/'+this.currentOrderId]);
    }

}
