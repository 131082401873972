import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { DynamicFormModule } from 'app/dynamic-form/dynamic-form.module';
import {IntroModule} from "app/main/intro/intro.module";
import {QuestionaireModule} from "app/main/questionaire/questionaire.module";
import {ErrorInterceptor} from "app/_helpers/error.interceptor";
import {AppRoutingModule} from "app/app.routing";
import {LoginModule} from "app/main/login/login.module";
import {AuthGuard} from "./_helpers/auth.guard";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {AuthInterceptor} from "./_helpers/auth.interceptor";
import {ToastrModule} from "ngx-toastr";

const appRoutes: Routes = [
    {
        path      : '',
        redirectTo: 'intro',
        pathMatch: 'full'
    },
    {
        path        : 'q/:id',
        loadChildren: () => import('./main/questionaire/questionaire.module').then(m => m.QuestionaireModule)
    },
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right'
        }),
        HttpClientModule,
        DynamicFormModule,
        AppRoutingModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        LoginModule,

        // App modules
        LayoutModule,
        SampleModule,
        IntroModule,
        QuestionaireModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
