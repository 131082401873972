export const locale = {
    lang: 'sk',
    data: {
        Q:{
            THANKS:'Ďakujeme!',
            SUBMITED:'Dotazník bol úspešne odoslaný.',
            GREETING:'Vitajte v dotazníku pre 360-stupňovú spätnú väzbu.',
            TEXT:'V každom riadku je jeden výrok popisujúci správanie hodnoteného v pracovnom živote. Na škále od 1 do 5 uveďte, ako často hodnotený toto správanie prejavuje. V prípade, že neviete na základe svojej interakcie s hodnoteným tento výrok posúdiť, uveďte N/A:',
            NOT_FILLED_TITTLE:'Vyplňte prosím všetky otázky.',
            NOT_FILLED:'',
            NOT_SENT_TITTLE:'Error',
            NOT_SENT:'Nepodarilo sa odoslať dotazník. Skúste to prosím znovu.',
            V1:'1 - nikdy',
            V2:'2 - málokedy',
            V3:'3 - niekedy',
            V4:'4 - často',
            V5:'5 - vždy',
            V1R:'1 - vždy',
            V2R:'2 - často',
            V3R:'3 - niekedy',
            V4R:'4 - málokedy',
            V5R:'5 - nikdy',
            VNA:'N/A - neviem posúdiť',
            VNULL:'Vyberte možnosť',
            SUBMIT:'Odoslať',

            NAME:"Vaše meno",
            NAME_PROPMPT:"Napíšte vaše celé meno",
            DEPARTMENT:"Oddelenie",
            D1:"IT",
            D2:"HR",
            D3:"Účtovné",
            D4:"Bezpečnosť",
            DNULL:"Vyberte jednu z možností",

            Q1:'1. Preberá osobnú zodpovednosť za vlastné činy a výsledky.',
            Q2:'2. Vždy koná s ohľadom na dôsledky.',
            Q3:'3. Pristupuje k firme „ako k svojmu“ a je čestný.',
            Q4:'4. Je spoľahlivý v plnení svojich dohôd a sľubov.',
            Q5:'5. Overuje si správnosť údajov, na základe ktorých sa rozhoduje.',
            Q6:'6. Jasne formuluje svoje očakávania.',
            Q7:'7. Stanovuje jasné ciele.',
            Q8:'8. Vedie ostatných k tomu, aby prebrali zodpovednosť za svoje výsledky.',
            Q9:'9. Spolupracuje s ostatnými zamestnancami pri hľadaní riešení.',
            Q10:'10. Iniciatívne žiada o ich názory a myšlienky iných pred rozhodnutím.',
            Q11:'11. Vo svojom konaní sa zameriava na efektívne riešenie problémov.',
            Q12:'12. Uvažuje z hľadiska celej spoločnosti, nie len z hľadiska svojho oddelenia.',
            Q13:'13. Proaktívne predchádza vzniku problémov.',
            Q14:'14. Vie si stanoviť priority v súlade s cieľmi spoločnosti',
            Q15:'15. Dosahuje výsledky napriek prekážkam.',
            Q16:'16. Začaté riešenia doťahuje do konca.',
            Q17:'17. Zo skupiny ľudí dokáže vytvoriť výkonný tím.',
            Q18:'18. Pozerá sa na veci strategicky a z dlhodobého hľadiska.',
            Q19:'19. Rozhodovanie deleguje na najnižšiu možnú úroveň.',
            Q20:'20. S chuťou a nadšením mení veci k lepšiemu.',
            Q21:'21. Neustále vylepšuje pracovné procesy.',
            Q22:'22. Dáva návrhy na nové postupy a riešenia.',
            Q23:'23. Zmeny prijíma otvorene.',
            Q24:'24. Je ochotný učiť sa a zlepšovať.',
            Q25:'25. Učí sa zo spätnej väzby a z chýb.',
            Q26:'26. Aktívne vyhľadáva podnety na učenie sa.',
            Q27:'27. Poskytuje iným konštruktívnu spätnú väzbu s dobrým úmyslom.',
            Q28:'28. Určuje dynamické tempo.',
            Q29:'29. Svojim príkladom vytvára inšpirujúce prostredie.',
            Q30:'30. Inšpiruje a povzbudzuje ostatných, aby boli zodpovední za svoj vlastný rozvoj.',
            Q31:'31. Viditeľne podporuje tých, ktorí dosahujú vynikajúce výsledky.',
            Q32:'32. Je podporujúci.',
            Q33:'33. S ostatnými jedná priamo a otvorene.',
            Q34:'34. Počúva a zaujíma sa o to, čo iní hovoria.',
            Q35:'35. Váži si druhých bez ohľadu na ich postavenie v hierarchii, ich rod, vek, rasu.',
            Q36:'36. Vie sa tešiť z úspechov iných.',
            Q37:'37. V práci vytvára pozitívnu atmosféru.',
            Q38:'38. Berie obavy ľudí do úvahy – vysvetľuje, počúva a odpovedá na otázky.',
            Q39:'39. Ku všetkým pristupuje spravodlivo, vyhýba sa uprednostňovaniu jednotlivcov.',
            Q40:'40. Pozná svojho zákazníka a jeho potreby.',
            Q41:'41. Vytvára si vzťahy so zákazníkmi tak, že rozumie ich očakávaniam.',
            Q42:'42. Plní záväzky voči našim interným a externým zákazníkom.',
            Q43:'43. Zabezpečuje dodržiavanie interných a externých kvalitatívnych noriem.',
            Q44:'44. Aktívne vyhľadáva pre spoločnosť nové obchodné príležitosti.',
            Q45:'45. Buduje zákaznícku lojalitu a napĺňa očakávania zákazníkov.',
            Q46:'46. Využíva schopnosti tímu v snahe vytvoriť čo najlepšie riešenie pre zákazníka.',
            Q47:'47. Aké správanie, ktoré posudzovaná osoba doteraz nepoužíva, by ste u nej uvítali?',
            Q48:'48. Aké správanie by posudzovaná osoba mala prestať používať?',
            Q49:'49. Aké správanie, ktoré posudzovaná osoba používa, by mala používať aj naďalej?',

        },
        S:{
            V1:'Nikdy',
            V2:'Málokedy',
            V3:'Niekedy',
            V4:'Často',
            V5:'Vždy',
            V0:'N/A - neviem posúdiť',
            VNULL:'Vyberte jednu z možností',
        }
    }
};
