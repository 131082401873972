import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'docs',
        title    : 'Docs',
        translate: 'NAV.DOCS',
        type     : 'group',
        badge    : {
            title    : '1',
            bg       : '#F44336',
            fg       : '#FFFFFF'
        },
        children : [
            {
                id       : 'intro',
                title    : 'Intro',
                translate: 'NAV.INTRO.TITLE',
                type     : 'item',
                icon     : 'import_contacts',
                url      : '/intro'
            },
        ]
    },
    {
        id       : 'questionaires',
        title    : 'Questionaires',
        translate: 'NAV.QUESTIONAIRES.TITLE',
        type     : 'group',
        badge    : {
            title    : '1',
            bg       : '#F44336',
            fg       : '#FFFFFF'
        },
        children : [
        ]
    }
];
