export const locale = {
    lang: 'en',
    data: {
        INTRO:{
            GREETING:'Welcome',
            TEXT:'<p>to the <b>360-degree feedback platform environment.</b> Your colleagues, the people you work with, would like to receive constructive feedback on their skills and behavior. This will be helpful for their further development. </p>' +
            '<p>The feedback will be provided using a <b>structured questionnaire</b>. Your answers are anonymous. Processed data will be sent to the person as a statistical report structured by different groups of assessors (direct superior, peers, subordinates).</p>' +
            '<p><b>Self-assessment</b> is also part of the feedback. So if you are an assessed person as well, remember to fill in your questionnaire too. You will be able to compare your answers with other colleagues. This output will be available only to you, so we recommend you take this opportunity for sincere self-reflection.</p>' +
            '<div class="mat-title mt-32">How to proceed? </div>' +
            '<p>On the left side, you can see names of colleagues who you are going to give feedback on. Click on the name to open the questionnaire about a specific colleague and start the evaluation.</p>' +
            '<p><b>Attention</b>: If you don\'t submit the questionnaire, your answers will not be saved. Please be sure therefore to complete the questionnaire in one go. It is not possible to return the questionnaire once you have sent it. <br/> You can log out of the system after sending the questionnaire and return to it via a link in the email.</p>' +
            '<p><b>Here are some recommendations concerning the evaluation:</b></p>' +
            '<ul>' +
            '<li>Each statement describes the behavior of the evaluated person. Rate each statement on a scale from “always“ to “never“, depending on how often the behavior occurs. In case you have no experience with a situation where the behavior could be observed, please select “N/A“.</li>' +
            '<li>When filling in the questionnaire, please be honest, and have in mind there are no “right“ or “wrong“ answers.</li>' +
            '<li>Do not be afraid to use the full range of the evaluation scale.</li>' +
            '<li>Do not be influenced by the recent period or recent experience. Try to focus on the period of last year.</li>' +
            '<li>Do not be influenced by one significant feature of the evaluated person, and justify each statement individually.</li>' +
            '</ul>' +
            '<p>Thank you in advance.</p>',
        }
    }
};
