import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormButtonComponent } from '../form-button/form-button.component';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {FormRadioComponent} from "../form-radio/form-radio.component";
import {FormConfig} from "../../models/form-config.interface";
import {FormSpacerComponent} from "../form-spacer/form-spacer.component";
import {FormSliderComponent} from "../form-slider/form-slider.component";
import {FormRadio2Component} from "../form-radio2/form-radio2.component";
import {FormTextareaComponent} from "../form-textarea/form-textarea.component";
import {FormTextarea2Component} from "../form-textarea2/form-textarea2.component";
import {FormDivComponent} from "../form-div/form-div.component";

const components: {[type: string]: Type<Field>} = {
  div: FormDivComponent,
  button: FormButtonComponent,
  input: FormInputComponent,
  textarea: FormTextareaComponent,
  textarea2: FormTextarea2Component,
  select: FormSelectComponent,
  radio: FormRadioComponent,
  radio2: FormRadio2Component,
  slider: FormSliderComponent,
  spacer: FormSpacerComponent,
};

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input()
  field: FieldConfig;

  @Input()
  config: FormConfig;

  @Input()
  group: FormGroup;

  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnChanges() {
    if (this.component) {
      this.component.instance.field = this.field;
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {
    if (!components[this.field.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.field.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.field.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.field = this.field;
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
