import { Component, OnInit } from '@angular/core';


import { locale as english } from './i18n/en';
import { locale as slovak } from './i18n/sk';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

    constructor(private translationLoader: FuseTranslationLoaderService)
    {
        this.translationLoader.loadTranslations(english, slovak);
    }

  ngOnInit(): void {
  }

}
