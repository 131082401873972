import { Component, OnInit } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FieldConfig} from "../../models/field-config.interface";
import {FormConfig, FormConfigOption} from "../../models/form-config.interface";
import {Field} from "../../models/field.interface";
import {MatRadioChange} from "@angular/material/radio";

@Component({
  selector: 'app-form-radio2',
  templateUrl: './form-radio2.component.html',
  styleUrls: ['./form-radio2.component.scss']
})
export class FormRadio2Component implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;

    selectedValue:string = "NULL";
    selectedOption:FormConfigOption = {style:"0", name:"Q.VNULL", value:"0"};

    radioChange(event: MatRadioChange, option:FormConfigOption) {

        if(event.source.checked === true)
        {
            this.selectedValue = option.value;
            this.selectedOption = option;
        }
    }
}