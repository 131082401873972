import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {Order} from "../../_models/order";
import {AuthenticationService} from "../../_services/authentication.service";
import {Assessor} from "../../_models/assessor";

@Injectable({
  providedIn: 'root'
})
export class QuestionaireService {

    private _orderSubject: BehaviorSubject<Order> = new BehaviorSubject(null);
    public currentOrder: Observable<Order> = this._orderSubject.asObservable();

  constructor(private authenticationService: AuthenticationService) { }

    changeOrder(order: Order) {
        this._orderSubject.next(order);
    }

    getOrderByKey(key:string):Order
    {
        let assessor:Assessor = this.authenticationService.currentUserSubject.getValue();
        if(assessor)
        {
            for(let order of assessor.orders)
            {
                if(order.key == key)
                {
                    return order;
                }
            }
        }

        return null;
    }
}
