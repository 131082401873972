import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from "./_helpers/auth.guard";
import {LoginComponent} from "./main/login/login.component";
import {IntroComponent} from "./main/intro/intro.component";

const routes: Routes = [
    { path: '', component: LoginComponent},
    { path: 'intro', component: IntroComponent, canActivate: [AuthGuard]  },
    {
        path        : 'q/:id',
        loadChildren: () => import('./main/questionaire/questionaire.module').then(m => m.QuestionaireModule),
        canActivate: [AuthGuard]
    },
    {
        path        : 'qd/:id',
        loadChildren: () => import('./main/questionaire/questionaire.module').then(m => m.QuestionaireModule),
        canActivate: [AuthGuard]
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '/' }
];

export const AppRoutingModule = RouterModule.forRoot(routes);