import { Component, OnInit } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FieldConfig} from "../../models/field-config.interface";
import {FormConfig} from "../../models/form-config.interface";
import {Field} from "../../models/field.interface";

@Component({
  selector: 'app-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss']
})
export class FormSliderComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;
}
