<div class="dynamic-field"
     [formGroup]="group">



    <div class="fuse-card mat-elevation-z2" fxLayout="column" fxFlex="100">

        <div class="caard-header">
            <div class="caard-title label">{{ 'Q.' + field.name | translate}}</div>
        </div>

        <div class="caard-body">

            <div fxLayout="row wrap">

                <div [innerHTML] = "selectedOption.name | translate" fxHide.gt-xs class="label" [ngClass]="'vc' + selectedOption.style" fxLayoutAlign="start center"></div>

                <mat-radio-group fxFlex.xs="noshrink" fxLayout="row wrap" [formControlName]="field.name" fxLayoutAlign="end start" fxLayoutAlign.gt-xs="center start">
                    <ng-container *ngFor="let option of config.options">

                        <mat-radio-button
                            [value]="option.value"
                            [ngClass.xs]="'v' + option.style + ' pr-0 pl-8 pt-8'"
                            [ngClass.sm]="'vc' + option.style + ' pr-4 pl-8 pt-8'"
                            [ngClass.gt-sm]="'vc' + option.style + ' pr-8 pl-12 pt-8'"
                            (change)="radioChange($event, option)"
                        >
                            <!--<span [innerHTML] = "selectedLabel" fxHide.gt-sm *ngIf="selectedLabel == option.name"></span>-->
                            <span [innerHTML] = "option.name | translate" fxHide.xs></span>
                            <!--<span [innerHTML] = "'INTRO.TEXT' | translate">{{option.name}}</span>-->
                        </mat-radio-button>
                        <div class="mr-8 ml-8" fxHide.xs></div>
                    </ng-container>

                    <!--<div *ngIf="this.invalid && (this.dirty || this.touched)" class="alert alert-danger">
                        <div>
                            Problem
                        </div>
                    </div>-->
                </mat-radio-group>


            </div>
        </div>
    </div>

    <!--<div class="fuse-card mat-elevation-z2" fxLayout="column" fxFlex="100">




        <div class="p-16 pb-0">
            <div class="h1 font-weight-300">{{ field.label }}</div>
        </div>

        <div class="card-divider"></div>

        <mat-radio-group aria-label="Select an option" [formControlName]="field.name" formGroupName="">
            <ng-container *ngFor="let option of config.options">

                <mat-radio-button
                    [value]="option.value"
                >
                </mat-radio-button>

            </ng-container>

        </mat-radio-group>
    </div>-->

    <!--<div fxLayout="row wrap" fxLayout.lt-sm="column">
        <div fxFlex="66" class="pr-1">
            <label style.color="blue">{{ field.label }}</label>
        </div>
        &lt;!&ndash;<input *ngFor="let option of config.options"
               type="radio"
               value="false"
               ng-model="config.name">&ndash;&gt;
        <div fxFlex="noshrink" class="pr-1">
            <mat-radio-group aria-label="Select an option" [formControlName]="field.name" formGroupName="">
                <ng-container *ngFor="let option of config.options">

                    <mat-radio-button
                        [value]="option.value"
                    >
                    </mat-radio-button>

                </ng-container>

            </mat-radio-group>
        </div>
    </div>-->
</div>