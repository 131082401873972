import { Component, OnInit } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FieldConfig} from "../../models/field-config.interface";
import {FormConfig} from "../../models/form-config.interface";
import {Field} from "../../models/field.interface";

@Component({
  selector: 'app-form-spacer',
  templateUrl: './form-spacer.component.html',
  styleUrls: ['./form-spacer.component.scss']
})
export class FormSpacerComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;
}
