<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo.svg">
            </div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Key</mat-label>
                    <input matInput type="text" formControlName="key">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Key is required
                    </mat-error>
                </mat-form-field>
                <mat-error *ngIf="error != ''" style="text-align:center">{{ error }}</mat-error>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid"
                        (click)="onSubmit()"
                >
                    LOGIN
                </button>

            </form>

        </div>

    </div>

</div>
