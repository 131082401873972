export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'DOCS': 'Docs',
            'INTRO'        : {
                'TITLE': 'Intro'
            },
            'QUESTIONAIRES'        : {
                'TITLE': 'Questionaires'
            }
        },
        'UI': {
            'SESSION_OUT': 'Your session has ended. Please login again.',
            'SESSION_OUT_TITTLE': 'Authentication error'
        }
    }
};
