import {Component, OnInit, ViewChild} from '@angular/core';
import {FieldConfig} from "../../models/field-config.interface";
import {FormGroup} from "@angular/forms";
import {Field} from "../../models/field.interface";
import {FormConfig, FormConfigOption} from "../../models/form-config.interface";
import {MatRadioChange, MatRadioGroup} from "@angular/material/radio";

@Component({
  selector: 'app-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss'],
})

export class FormRadioComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;

    selectedValue:string = "NULL";

    radioChange(event: MatRadioChange, option) {

        if(event.source.checked === true)
        {
            this.selectedValue = option.value;
        }
    }
}
