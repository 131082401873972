export const locale = {
    lang: 'en',
    data: {
        Q:{
            THANKS:'Thank you!',
            SUBMITED:'Form has been sent successfully.',
            GREETING:'Welcome in 360 degree feedback questionnaire.',
            TEXT:'In each line, you will find statement describing behaviour of an assessed person in working life. On a scale from 1 to 5, please rate how often does evaluated manager behaves in described way. In case you are not able to assess described behaviour (for example, due to a low interaction with the person), please choose N/A:',
            NOT_FILLED_TITTLE:'Please answer all questions.',
            NOT_FILLED:'',
            NOT_SENT_TITTLE:'Error',
            NOT_SENT:'Cannot save the form. Please try again.',
            V1:'1 - Never',
            V2:'2 - Rarely',
            V3:'3 - Sometimes',
            V4:'4 - Very often',
            V5:'5 - Always',
            V1R:'1 - Always',
            V2R:'2 - Very often',
            V3R:'3 - Sometimes',
            V4R:'4 - Rarely',
            V5R:'5 - Never',
            VNA:'N/A - do not know',
            SUBMIT:'Submit',

            NAME:"Full name",
            NAME_PROPMPT:"Enter your name",
            DEPARTMENT:"Department",
            D1:"IT",
            D2:"HR",
            D3:"Accounting",
            D4:"Security",
            DNULL:"Select an option",

            Q1:'1. Takes personal responsibility for own actions and results.',
            Q2:'2. Always acts with regard to the consequences.',
            Q3:'3. Approaches the company "as his own" and is honest.',
            Q4:'4. Is reliable in fulfilling his agreements and promises.',
            Q5:'5. Verifies the accuracy of the data on which he decides.',
            Q6:'6. Clearly formulates his expectations.',
            Q7:'7. Sets clear goals.',
            Q8:'8. Leads others to take responsibility for their results.',
            Q9:'9. Cooperates with other employees to find solutions.',
            Q10:'10. Takes initiative in obtaining opinion of others prior to decision making.',
            Q11:'11. While taking actions, focuses on effective problem solving.',
            Q12:'12. Thinks about the whole company, not only in terms of its department.',
            Q13:'13. Proactively prevents problems.',
            Q14:'14. Is able to set priorities in line with the company\'s goals.',
            Q15:'15. Achieves the results despite the obstacles.',
            Q16:'16. Pulls started solutions to the end.',
            Q17:'17. Can create a highly performing team from a group of people.',
            Q18:'18. Looks at things strategically and in the long term run.',
            Q19:'19. Delegates decision-making to the lowest possible level.',
            Q20:'20. With enthusiasm changes things for the better.',
            Q21:'21. Continuously improves working processes.',
            Q22:'22. Gives suggestions for new procedures and solutions.',
            Q23:'23. Accepts changes openly.',
            Q24:'24. Is willing to learn and improve.',
            Q25:'25. Learns from feedback and mistakes.',
            Q26:'26. Actively searches oportunities for learning.',
            Q27:'27. Provides others with constructive feedback with good intentions.',
            Q28:'28. Determines dynamic tempo.',
            Q29:'29. By example creates an inspiring environment.',
            Q30:'30. Inspires and encourages others to be responsible for their own development.',
            Q31:'31. Visibly supports those who achieve excellent results.',
            Q32:'32. Is supportive.',
            Q33:'33. Acts with others directly and openly.',
            Q34:'34. Is listening and cares what others are saying.',
            Q35:'35. Respects others regardless of their position in the hierarchy, their gender, age, race.',
            Q36:'36. Genuinly enjoys the success of others.',
            Q37:'37. Creates a positive atmosphere at work.',
            Q38:'38. Takes people\'s concerns into account - explains, listens, and answers questions.',
            Q39:'39. Approaches everyone fairly, avoids the preference of individuals.',
            Q40:'40. Knows his customer and his needs.',
            Q41:'41. Builds relationships with customers so that he understands their expectations.',
            Q42:'42. Fulfills obligations towards our internal and external customers.',
            Q43:'43. Ensures compliance with internal and external quality standards.',
            Q44:'44. Actively searches for new business opportunities for the company.',
            Q45:'45. Builds customer loyalty and meets customer expectations.',
            Q46:'46. Uses the team\'s capabilities to create the best solution for customer.',
            Q47:'47. What behavior should the assessed person start using?',
            Q48:'48. What behavior should the assessed person stop using?',
            Q49:'49. What behavior should the assessed person continue to use?',

        },
        S:{
            V1:'Never',
            V2:'Rarely',
            V3:'Sometimes',
            V4:'Very often',
            V5:'Always',
            V0:'N/A - do not know',
            VNULL:'Select an option',
        }
    }
};
