import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from "@angular/material/divider";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormSpacerComponent } from './components/form-spacer/form-spacer.component';
import { FormRadio2Component } from './components/form-radio2/form-radio2.component';
import { FormSliderComponent } from './components/form-slider/form-slider.component';
import {TranslateModule} from "@ngx-translate/core";
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormTextarea2Component } from './components/form-textarea2/form-textarea2.component';
import { FormDivComponent } from './components/form-div/form-div.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
      MatRadioModule,
      TranslateModule,
      FlexLayoutModule,
      MatDividerModule,
  ],
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormRadioComponent,
    FormSpacerComponent,
    FormRadio2Component,
    FormSliderComponent,
    FormTextareaComponent,
    FormTextarea2Component,
    FormDivComponent,
  ],
  exports: [
    DynamicFormComponent
  ],
  entryComponents: [
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormRadioComponent
  ]
})
export class DynamicFormModule {}
