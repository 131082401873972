import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {FormConfig} from "../../models/form-config.interface";

@Component({
  selector: 'form-select',
  styleUrls: ['form-select.component.scss'],
  template: `
      <div
              class="dynamic-field form-select"
              [formGroup]="group">
          <label>{{ 'Q.'+field.label | translate }}</label>
          <select [formControlName]="field.name">
              <option value="">{{ 'Q.'+ field.placeholder | translate}}</option>
              <option *ngFor="let option of field.options">
                  {{ 'Q.'+ option | translate }}
              </option>
          </select>
      </div>
  `
})
export class FormSelectComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;
}
