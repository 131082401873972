import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {FormConfig} from "../../models/form-config.interface";

@Component({
  selector: 'form-input',
  styleUrls: ['form-input.component.scss'],
  template: `
      <div
              class="dynamic-field form-input"
              [formGroup]="group">
          <label>{{ 'Q.'+field.label | translate}}</label>
          <input
                  type="text"
                  [attr.placeholder]="'Q.'+field.placeholder | translate"
                  [formControlName]="field.name">
      </div>
  `
})
export class FormInputComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;
}
