import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {FormConfig} from "../../models/form-config.interface";

@Component({
  selector: 'form-button',
  styleUrls: ['form-button.component.scss'],
  template: `
      <div
              class="dynamic-field form-button"
              [formGroup]="group">
          <button
                  [disabled]="field.disabled"
                  type="submit">
              {{ 'Q.' + field.label | translate }}
          </button>
      </div>
  `
})
export class FormButtonComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;
}
