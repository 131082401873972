import { Component, OnInit } from '@angular/core';
import {Field} from "../../models/field.interface";
import {FormConfig} from "../../models/form-config.interface";
import {FieldConfig} from "../../models/field-config.interface";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-form-div',
  templateUrl: './form-div.component.html',
  styleUrls: ['./form-div.component.scss']
})
export class FormDivComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
