<div class="dynamic-field"
     [formGroup]="group">

    <div fxLayout="row wrap" fxLayout.lt-sm="column" class="radioRow">
        <div fxFlex="66" class="pr-1">
            <label style.color="blue">{{ 'Q.' + field.name | translate}}</label>
            <div [innerHTML] = "'S.V'+selectedValue | translate" class="label" [ngClass]="'vc' + selectedValue" fxLayoutAlign="start center"></div>
        </div>

        <div fxFlex="noshrink" class="pr-1">

            <mat-radio-group aria-label="Select an option" [formControlName]="field.name">
                <ng-container *ngFor="let option of config.options">

                    <mat-radio-button
                        [value]="option.value"
                        [matTooltip]="option.name"
                        [ngClass]="'v' + option.value + ' pl-8 pr-0'"
                        (change)="radioChange($event, option)"
                    >
                    </mat-radio-button>

                </ng-container>

            </mat-radio-group>
        </div>
    </div>
</div>