export const locale = {
    lang: 'sk',
    data: {
        INTRO:{
            GREETING:'Vitajte',
            TEXT:'<p>v aplikácii na poskytovanie <b>360-stupňovej spätnej väzby.</b> Kolega/kolegovia, s ktorými spolupracujete, by od Vás radi získali konštruktívnu spätnú väzbu na svoje manažérske zručnosti a správanie. Táto im bude slúžiť pre ďalší rozvoj. </p>' +
            '<p>Spätnú  väzbu budete poskytovať prostredníctvom <b>štruktúrovaného dotazníka</b>. Vaše odpovede sú anonymné. Spracované dáta budú zaslané hodnotenej osobe vo forme štatistického vyhodnotenia podľa jednotlivých skupín hodnotiteľov (nadriadený, podriadení, kolegovia).  </p>' +
            '<p>Súčasťou 360-stupňovej spätnej väzby je aj <b>sebahodnotenie</b>. Preto ak ste aj hodnoteným manažérom, nezabudnite si vyplniť aj svoj dotazník. Svoje odpovede si budete môcť porovnať s odpoveďami ostatných kolegov. Výstup bude k dispozícii iba Vám, preto odporúčame využiť túto príležitosť na úprimnú sebareflexiu.  </p>' +
            '<div class="mat-title mt-32">Ako na to? </div>' +
            '<p>Na ľavej strane vidíte mená kolegov, ktorým máte poskytnúť spätnú väzbu. Po kliknutí na meno sa otvorí dotazník daného kolegu. </p>' +
            '<p><b>Upozornenie</b>: Ak dotazník neodošlete, Vaše odpovede sa neuložia. Preto jeden dotazník <b>vyplňte na jedenkrát</b>. Po odoslaní už nie je možné sa k dotazníku vrátiť. Zo systému je možné sa odhlásiť po odoslaní dotazníka a opätovne sa do neho vrátiť cez link v emaile. </p>' +
            '<p><b>K hodnoteniu dávame do pozornosti niekoľko odporúčaní:</b></p>' +
            '<ul>' +
            '<li>Jednotlivé výroky popisujú prejavy správania hodnoteného. Každý výrok ohodnoťte na škále od „vždy“ po „nikdy“ podľa toho, ako často sa dané správanie objavuje. V prípade, že ste hodnoteného nezažili v situácii, kde by prejavoval dopytované správanie, označte odpoveď „neviem posúdiť“,</li>' +
            '<li>Pri vyplňovaní dotazníka buďte úprimná/ý, neexistujú žiadne „správne“ alebo „nesprávne“ odpovede.</li>' +
            '<li>Nebojte sa využívať celú hodnotiacu škálu.</li>' +
            '<li>Nedajte sa ovplyvniť posledným obdobím alebo skúsenosťou. Zamerajte sa na obdobie posledného roka.</li>' +
            '<li>Nenechajte sa ovplyvniť jednou výraznou vlastnosťou hodnoteného, každý výrok posudzujte individuálne.</li>' +
            '</ul>' +
            '<p>Vopred ďakujeme.</p>',
        }
    }
};
