import { Injectable } from '@angular/core';
import {AuthenticationService} from "../_services/authentication.service";
import {Assessor} from "../_models/assessor";
import { navigation } from 'app/navigation/navigation';
import {FuseNavigationService} from "../../@fuse/components/navigation/navigation.service";
import {FuseNavigation} from "../../@fuse/types/fuse-navigation";

@Injectable({
  providedIn: 'root'
})

export class NavigationService {

  constructor(private authenticationService: AuthenticationService,
                private _fuseNavigationService: FuseNavigationService) {


  }

  init():void
  {
      this.authenticationService.currentUser.subscribe(
          (assessor) => {
              this.updateNav(assessor);
          });
  }

  updateNav3(assessor:Assessor):void
  {
      // Prepare the new nav item
      const newNavItem = {
          id      : 'custom-item',
          title   : 'Custom Item',
          type    : 'item',
          function: () => {
              alert('Custom function!');
          }
      };

      // Add the new nav item at the beginning of the navigation
      this._fuseNavigationService.updateNavigationItem( 'questionaires', newNavItem);
  }

  updateNav(assessor:Assessor):void
  {

      let oldNav:FuseNavigation = this._fuseNavigationService.getNavigationItem( 'questionaires');
      let idsToRemove:string[] = [];
      if(oldNav.children)
      {
          for(let item of oldNav.children)
          {
              idsToRemove.push(item.id);
          }

          for(let id of idsToRemove)
          {

              this._fuseNavigationService.removeNavigationItem(id);
          }
      }


      //console.log("updateNav", assessor);

      let newNav:FuseNavigation = {
          id       : 'questionaires',
          title    : 'Questionaires',
          translate: 'NAV.QUESTIONAIRES.TITLE',
          type     : 'group',
          children : [
          ]
      };

      //this._fuseNavigationService.updateNavigationItem( 'questionaires', newNav);

      if(assessor != null)
      {
          for(let order of assessor.orders)
          {
              let navChild:any = {
                  id       : order.key,
                  title    : order.target.name,
                  type     : 'item',
                  icon     : 'face',
                  url      : `/q${order.isSubmited?"d":""}/${order.key}`
              };

              if(order.isSubmited)
              {
                  navChild.badge = {
                      title    : '✓',
                      bg       : '#04f41d',
                      fg       : '#FFFFFF'
                  };
              }

              newNav.children.push(navChild);
          }
      }


      this._fuseNavigationService.updateNavigationItem( 'questionaires', newNav);

  }
}
