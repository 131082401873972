import { NgModule } from '@angular/core';
import { QuestionaireComponent } from './questionaire.component';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import { FuseSharedModule } from '@fuse/shared.module';
import {DynamicFormModule} from "../../dynamic-form/dynamic-form.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {AuthGuard} from "../../_helpers/auth.guard";


const routes = [
    {
        path     : 'q/:id',
        component: QuestionaireComponent,
        canActivate: [AuthGuard]
    },
    {
        path     : 'qd/:id',
        component: QuestionaireComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
  declarations: [QuestionaireComponent],
  imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        DynamicFormModule,
        FuseSharedModule,
        NgxSpinnerModule
  ]
})
export class QuestionaireModule { }
