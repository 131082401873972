<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/logoA.svg">
        <img class="logo-text logo-icon-B" src="assets/images/logos/logoB.svg">
        <!--<span class="logo-text ">TIMAN</span>-->
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">Tester Test</div>
        <div class="h5 email hint-text mt-8">tester.test@timan.sk</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/Velazquez.jpg">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
