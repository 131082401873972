export const locale = {
    lang: 'sk',
    data: {
        'NAV': {
            'DOCS': 'Dokumenty',
            'INTRO'        : {
                'TITLE': 'Úvod'
            },
            'QUESTIONAIRES'        : {
                'TITLE': 'Dotazníky'
            }
        },
        'UI': {
            'SESSION_OUT': 'Platnosť prihlásenia skončíla. Prihláste sa znovu prosím.',
            'SESSION_OUT_TITTLE': 'Chyba autentifikácie'
        }
    }
};
