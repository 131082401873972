import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../_services/authentication.service";
import {first, map, tap} from "rxjs/internal/operators";

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    isLoginFailed = false;
    returnUrl: string;
    error = '';

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    )
    {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/intro']);
        }

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        /*this.route.queryParams
            .subscribe(params => {
                console.log(params);
                    if(params.hasOwnProperty("key"))this.f.key.reset(params.key);
                }
            );*/

        let key:string = "";
        //console.log(this.route.snapshot.queryParams);
        if(this.route.snapshot.queryParams.hasOwnProperty("key"))
        {
            key = this.route.snapshot.queryParams.key;
            key = key.trim();
            key = key.split("%0D").join("");
            key = key.split("%0d").join("");
            key = key.split("\r").join("");
            key = key.split("\n").join("");
        }

        //key = "7f73fdb4-1c0a-49f3-ba07-da11f32fa6c6";

        this.loginForm = this._formBuilder.group({
            //email   : ['', [Validators.required, Validators.email]],
            key: [key, Validators.required]
        });



        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/intro';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit():void
    {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;


        let key:string = this.f.key.value;

        //this.error = "Server maintenance. Try again later.";
        //this.loading = false;
        //this.isLoginFailed = true;

        this.authenticationService.loginWithKey(key).subscribe(
            data => {
                this.isLoginFailed = false;
                this.router.navigate([this.returnUrl]);
            },
            err => {
                this.error = "Enter a valid key";
                this.loading = false;
                this.isLoginFailed = true;
            }
        );



        /*this.authenticationService.loginWithKey(this.f.key.value)
            .pipe(tap(
                data => {
                    console.log(data);
                    if(data)
                    {
                        this.router.navigate([this.returnUrl]);
                    }
                    else
                    {
                        this.error = "Enter a valid key";
                        this.loading = false;
                    }
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }));*/
    }
}
