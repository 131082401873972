import { NgModule } from '@angular/core';
import { IntroComponent } from './intro.component';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import { FuseSharedModule } from '@fuse/shared.module';


const routes = [
    {
        path     : 'intro',
        component: IntroComponent
    }
];

@NgModule({
  declarations: [IntroComponent],
  imports: [
      //RouterModule.forChild(routes),
      TranslateModule,
      FuseSharedModule
  ],
    exports     : [
        IntroComponent
    ]
})
export class IntroModule { }
