import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {FormConfig} from "../../models/form-config.interface";

@Component({
  selector: 'form-textarea',
  styleUrls: ['./form-textarea.component.scss'],
  template: `
      <div
              class="dynamic-field form-input"
              [formGroup]="group">
          <label>{{ 'Q.'+field.label | translate}}</label>
          <div><textarea class="ta"
                  [attr.rows]="field.rows"
                  [formControlName]="field.name" ></textarea></div>
      </div>
  `
})
export class FormTextareaComponent implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;
}
