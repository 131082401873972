import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import {FormConfig} from "../../models/form-config.interface";

@Component({
    selector: 'form-textarea2',
    styleUrls: ['./form-textarea2.component.scss'],
    templateUrl: './form-textarea2.component.html',
})
export class FormTextarea2Component implements Field {
    config: FormConfig;
    field: FieldConfig;
    group: FormGroup;
}
