<div class="dynamic-field"
     [formGroup]="group">

    <div class="fuse-card mat-elevation-z2" fxLayout="column" fxFlex="100">

        <div class="caard-header">
            <div class="caard-title label">{{ 'Q.' + field.name | translate}}</div>
        </div>

        <div class="caard-body">

            <div fxLayout="row wrap">

                <textarea class="ta"
                          [attr.rows]="field.rows"
                          [formControlName]="field.name" ></textarea>
            </div>
        </div>
    </div>
</div>