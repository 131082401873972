import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import jwt_decode from "jwt-decode";
import {User} from "../_models/user";
import {TokenStorageService} from "./token-storage.service";
import {tap} from "rxjs/internal/operators";
import {Assessor} from "../_models/assessor";

@Injectable
(
    { providedIn: 'root' }
)


export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<Assessor>;
    public currentUser: Observable<Assessor>;

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Assessor {
        return this.currentUserSubject.value;
    }

    loginWithKey(key: string):Observable<any>
    {
        //console.log("hey", key);

        return this.http.post<any>(`${environment.apiUrl}/auth/login`, {key}, this.httpOptions)
            .pipe(map(data => {
                //console.log("hey", data);
                let dec:any = jwt_decode(data);
                this.tokenStorage.saveToken(data);
                let assessor:Assessor = dec.assessor;
                if(assessor.key != key)assessor = null;
                this.currentUserSubject.next(assessor);
                return data;
            }));

        /*this.http.post<any>(`${environment.apiUrl}/auth/login`, {key}, this.httpOptions).toPromise()
            .then(response => {

            let dec:any = jwt_decode(response);
            this.tokenStorage.saveToken(response);
            let assessor:Assessor = dec.assessor;
            //console.log(assessor);
            if(assessor.key != key)assessor = null;
            this.currentUserSubject.next(assessor);

        }).catch(error => alert(error.message));;


        return this.currentUser;*/
    }

    submitOrder(orderKey:string, data:{[name: string]: any}):Observable<any>
    {
        return this.http.post<any>(`${environment.apiUrl}/q`, {key:orderKey, data:JSON.stringify(data)}, this.httpOptions)
            .pipe(map(data => {
                let assessor:Assessor = this.currentUserSubject.getValue();
                if(assessor)
                {
                    for(let order of assessor.orders)
                    {
                        if(order.key == orderKey)
                        {
                            order.isSubmited = true;
                            this.currentUserSubject.next(assessor);
                            return;
                        }
                    }
                }
                return data;
        }));

    }

    /*login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                //this.currentUserSubject.next(user);
                return user;
            }));
    }*/

    logout() {
        // remove user from local storage to log user out
        this.tokenStorage.signOut();
        this.currentUserSubject.next(null);
    }
}